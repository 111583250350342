import React, {useEffect, useReducer, useState} from 'react';
import SimplePageWrapper from '../../components/shared/simple-page-wrapper';
import {TimesheetViewType, TimeSheetGroupType} from '../../features/schedule/types';
import ScheduleTopBar from '../../components/Schedule/header';
import TabPanel from '../../components/shared/tab-panel';
import TimeSheetGrid from './components/timesheet-grid';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {getAllEmployees} from '../../features/employees/short';
import {getProjects} from '../../features/projects/filter';
import {getAbsencesTypes, getShiftsTypes} from '../../features/types';
import {AutoCompleteItemValue} from '../../components/shared/nextgen/selector/types';
import useTimeSheetView from './hooks/useTimeSheetView';
import moment from 'moment';
import {
    CreateShift,
    Hours,
    ScheduleViewStatusFilter,
    TimeSheetItem,
    TimeSheetItemStatus,
    TimeshiftUpdateState,
    TimeSheetItemType
} from '../../features/schedule/types';
import { ScheduleState } from './types';
import {getWorkCalendar, getWorkCalendars} from '../../features/settings/workcalendar';
import {Day, Year} from '../../features/settings/workcalendar/types';
import {useIntl} from 'react-intl';
import {
    toAbsence,
    toShift,
    convertTimeshiftUpdateStateToShift,
    isCreateShift,
    isEditShift
} from './functions';
import {getShift, updateShift} from '../../features/shifts';
import {updateAbsence} from '../../features/absences';

import _ from 'lodash';
import CreateShiftModal from '../../components/Schedule/create-modal';
import {REmployees} from '../../features/roles/types';
import {store} from '../../store/store';
import {clearSelected, selectIdOnEdit} from '../../features/modals';
import {Shift} from '../../features/shifts/types';
import EditModal from '../../components/Schedule/edit-modal';
import {Employee} from '../../features/employees/types';
import {getEmployee} from '../../features/employees';
import {getProject} from '../../features/projects';
import ProgressIndicator from '../../components/Schedule/progress-indicator';
import { Box, LinearProgress } from '@mui/material';

const getMonthDayFromDateString = (dateString: string) => {
    const date = dateString.split('/');
    return {
        month: parseInt(date[1]),
        day: parseInt(date[2])
    }
}

const daysInRange = (year: Year, dateRange: Array<string>): Day[] => {
    const from = getMonthDayFromDateString(dateRange[0])
    const to = getMonthDayFromDateString(dateRange[1])

    return year.months.filter((x) => x.month <= to.month && x.month >= from.month).map((m) => m.days.map((md) => ({
        ...md,
        month: m.month
    }))).flat(1)
        .filter((d) => (((d.month >= from.month && d.day >= from.day && d.month < to.month) || (d.month <= to.month && d.day <= to.day && d.month > from.month) || d.day <= to.day && d.day >= from.day)))
        .map((d) => ({day: d.day, customHours: d.customHours, isHoliday: d.isHoliday, isShort: d.isShort }));
}

export default function SchedulePage() {
    const today = moment();
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [updating, setUpdating] = useState<boolean>(false);

    const stateDispatchHandler = (state: ScheduleState, action: Partial<ScheduleState>): ScheduleState => {
        return {
            scheduleType: TimesheetViewType.DAY,
            dateRange: [
                today.clone().add(-1, 'week').format('yyyy/MM/DD'),
                today.format('yyyy/MM/DD')
            ],
            scheduleViewType: TimeSheetGroupType.EMPLOYEEVIEW,
            employees: [],
            projects: [],
            shiftType: [],
            absenceTypes: [],
            statusFilter: {
                normal: true,
                approved: true,
                billable: true
            },
            loading: true,
            isAnyRowExpand: false
        }
    }

    const initialState: ScheduleState = {
        scheduleType: TimesheetViewType.DAY,
        dateRange: [
            today.clone().add(-1, 'week').format('yyyy/MM/DD'),
            today.format('yyyy/MM/DD')
        ],
        scheduleViewType: TimeSheetGroupType.EMPLOYEEVIEW,
        employees: [],
        projects: [],
        shiftType: [],
        absenceTypes: [],
        statusFilter: {
            normal: true,
            approved: true,
            billable: true
        },
        loading: true,
        isAnyRowExpand: false
    }
    const [scheduleState, scheduleDispatch] = useReducer(stateDispatchHandler, initialState)
    const [scheduleType, setScheduleType] = useState<TimesheetViewType>(TimesheetViewType.DAY);
    const [hours, setHours] = useState<Hours | null>(null);
    const [selectedDateRange, setSelectedDateRange] = useState<Array<string>>(() => {
        return [
            today.clone().add(-1, 'week').format('yyyy/MM/DD'),
            today.format('yyyy/MM/DD')
        ]
    })
    const [selectedScheduleViewType, setSelectedScheduleViewType] = useState<TimeSheetGroupType>(TimeSheetGroupType.EMPLOYEEVIEW);
    const [selectedEmployees, setSelectedEmployees] = useState<Array<AutoCompleteItemValue>>([]);
    const [selectedProjects, setSelectedProjects] = useState<Array<AutoCompleteItemValue>>([]);
    const [selectedShiftTypes, setSelectedShiftTypes] = useState<Array<AutoCompleteItemValue>>([]);
    const [selectedAbsenceTypes, setSelectedAbsenceTypes] = useState<Array<AutoCompleteItemValue>>([]);
    const [statusFilter, setStatusFilter] = useState<ScheduleViewStatusFilter>({ normal: true, approved: true, billable: true });
    const [searchValue, setSearchValue] = useState<string>('');
    const [isAnyRowExpand, setIsAnyRowExpand] = useState(false);
    const [forceSwitchExpand, setForceSwitchExpand] = useState(false);
    const [dataLoading, setDataLoading] = useState<boolean>(true);


    // selector
    const employees = useAppSelector((selector) => selector.rEmployees);
    const projects = useAppSelector((selector) => selector.eProjects);
    const shiftType = useAppSelector((selector) => selector.types.shifts);
    const absenceType = useAppSelector((selector) => selector.types.absences);
    const shifts = useAppSelector((selector) => selector.shifts);
    const absences = useAppSelector((selector) => selector.absences);
    const { workCalendars, year } = useAppSelector((selector) => selector.workCalendar);
    const selectEdit = useAppSelector((store) => store.modals.selectedEdit)
    const { timeshifts: data } = useTimeSheetView(selectedDateRange, employees, selectedEmployees, selectedProjects, selectedShiftTypes, selectedAbsenceTypes, statusFilter, searchValue, selectedScheduleViewType);

    const loading = employees.length < 1 || projects.length < 1 || shiftType.total < 1 || absenceType.total < 1 || workCalendars.length < 1 || !year;

    const calculateAllWorkHours = (multiplier: number): number => {
        const primaryCalendar = workCalendars.find((wc) => wc.id == 1)!;
        const defaultHours = primaryCalendar.workHours;
        const defaultShortHours = primaryCalendar.shortWorkHours;

        const days = daysInRange(year, selectedDateRange).filter((d) => !d.isHoliday);
        return days.reduce((sum, a) => sum + (a.isShort ? a.customHours ? a.customHours : defaultShortHours : defaultHours), 0) * multiplier;
    }

    const bulkUpdateTimeshiftStatus = async (states: Array<TimeshiftUpdateState>) => {
        const toUpdate = states.map((s) => convertTimeshiftUpdateStateToShift(s, shifts.items));
        const promises: Promise<void>[] = [];

        toUpdate.forEach((us) => {
            promises.push(dispatch(updateShift(us)).unwrap().then())
        })

        setUpdating(true);
        Promise.all(promises)
            .finally(() => setUpdating(false));
    }

    const handleTimeshiftUpdate = (timeshift: TimeSheetItem) => {
        // TODO check if need update
        if (timeshift.timeshiftType === TimeSheetItemType.SHIFT) {
            dispatch(getProject(timeshift.targetId)).unwrap().then((r) => {
                dispatch(updateShift(toShift(timeshift, shifts.items, shiftType.items, r)))
            })
        } else {
            dispatch(updateAbsence(toAbsence(timeshift, absences.items, absenceType.items)))
        }
    };

    const handleAddNewShiftClick = async (targetTimeshift: TimeSheetItem) => {
        let employeeId: number | undefined = 0;
        if (targetTimeshift.timeshiftType === TimeSheetItemType.SHIFT) {
            employeeId = shifts.items.find((x) => x.id === targetTimeshift.id)?.employeeId
        } else {
            employeeId = absences.items.find((x) => x.id === targetTimeshift.id)?.employeeId
        }
        const foundEmployee = employees.find((e) => e.id === employeeId);

        beginCreateNewShift(foundEmployee, targetTimeshift.date);
    };

    const handleEditShiftClick = async (timeshift: TimeSheetItem) => {
        if (timeshift.timeshiftType !== TimeSheetItemType.SHIFT) {
            return
        }

        await dispatch(getShift(timeshift.id)).unwrap().then((shift) => {
            if (!shift) {
                return;
            }

            store.dispatch(selectIdOnEdit(shift))
        })
    }

    const getShiftEmployee = async (timeshift: TimeSheetItem): Promise<Employee> => {
        const employeeId = shifts.items.find((x) => x.id === timeshift.id)!.employeeId
        return await dispatch(getEmployee(employeeId)).unwrap()
    }

    const beginCreateNewShift = (employeeId?: REmployees, date?: string) => {
        const createShift: CreateShift = {
            employees: employeeId ? [employeeId] : employees,
        }
        if (date) {
            createShift.state = {
                date: date
            }
        }
        store.dispatch(selectIdOnEdit(createShift));
    };

    const handleShiftEditingComplete = () => {
        store.dispatch(clearSelected())
    };

    useEffect(() => {
        dispatch(getAllEmployees());
        dispatch(getProjects());
        dispatch(getShiftsTypes(0));
        dispatch(getAbsencesTypes(0));
        dispatch(getWorkCalendars()).unwrap().then((value) => {
            const primaryCalendar = value[0]!;
            const year = parseInt(today.format('yyyy'));
            dispatch(getWorkCalendar({ workCalendarId: primaryCalendar.id, year: year }))
        });
    }, [])

    useEffect(() => {
        setDataLoading(data.length < 1);
    }, [data.length]);

    useEffect(() => {
        if (!data) {
            return;
        }
        if (workCalendars.length < 1) {
            return;
        }
        if (!year) {
            return;
        }

        setHours({
            all: calculateAllWorkHours(employees.length),
            total: data.map((d) => d.hours).reduce((sum, a) => sum + a, 0),
            approved: data.filter((s) => s.status == TimeSheetItemStatus.APPROVED || s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0),
            billable: data.filter((s) => s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0)
        })

    }, [year, selectedDateRange, data]);

    return <SimplePageWrapper>
        {/* HEADER */}
        {
            !loading &&
            <ScheduleTopBar intl={intl} scheduleType={scheduleType}
                scheduleTypeSelected={setScheduleType}
                employees={employees}
                projects={projects}
                shiftTypes={_.chain(shiftType.items).filter((st) => st.active).orderBy((st) => st.id).value()}
                absenceTypes={_.chain(absenceType.items).filter((at) => at.active).orderBy((at) => at.id).value()}
                selectedEmployees={selectedEmployees}
                selectedProjects={selectedProjects}
                selectedDateRange={selectedDateRange}
                selectedViewType={selectedScheduleViewType}
                setEmployeesSelected={setSelectedEmployees}
                setSelectedProjects={setSelectedProjects}
                setSelectedDateRange={setSelectedDateRange}
                hours={hours}
                statusFilter={statusFilter}
                year={year}
                today={today}
                selectedShiftTypes={selectedShiftTypes} 
                selectedAbsenceTypes={selectedAbsenceTypes}
                searchValue={searchValue}
                setSelectedShiftTypes={setSelectedShiftTypes} 
                setSelectedAbsenceTypes={setSelectedAbsenceTypes}
                setSelectedViewType={setSelectedScheduleViewType}
                setStatusFilter={setStatusFilter}
                onCreateShiftClick={beginCreateNewShift}
                setSearchValue={setSearchValue}
                isAllRowsExpanded={isAnyRowExpand}
                onExpandClick={() => { setForceSwitchExpand((s) => !s) }} />
        }
        {/* BODY */}
        {<>
            <TabPanel index={TimesheetViewType.DAY} value={scheduleType}>
                { updating ? <LinearProgress hidden={!updating} sx={{ height: 2 }} /> : <Box sx={{ height: 2 }} /> }
                { !dataLoading && data && workCalendars.length > 0 && year && year.year > 0 &&
                    <TimeSheetGrid data={data}
                        shiftTypes={shiftType}
                        absenceTypes={absenceType}
                        calculateAllHours={(multiplier) => calculateAllWorkHours(multiplier)}
                        intl={intl}
                        onStateChange={bulkUpdateTimeshiftStatus}
                        viewType={selectedScheduleViewType}
                        projects={projects}
                        onTimeshiftUpdate={handleTimeshiftUpdate}
                        onAddNewShiftClick={handleAddNewShiftClick}
                        forceSwitch={forceSwitchExpand}
                        notifySwitch={setIsAnyRowExpand}
                        onEditShiftClick={async (timeshift) => await handleEditShiftClick(timeshift)}
                        getShiftEmployee={async (timeshift) => await getShiftEmployee(timeshift)}/> }
            </TabPanel>
            <TabPanel index={TimesheetViewType.WEEK} value={scheduleType}>
                <h2>Work in progress</h2>
            </TabPanel>
            <TabPanel index={TimesheetViewType.MONTH} value={scheduleType}>
                <h2>Work in progress</h2>
            </TabPanel>
        </>
        }
        {/* MODALS */}
        {selectEdit && isCreateShift(selectEdit) && <CreateShiftModal create={selectEdit as CreateShift} onCreate={handleShiftEditingComplete} shiftTypes={_.orderBy(shiftType.items, (i) => i.id)}/>}
        {selectEdit && isEditShift(selectEdit) && <EditModal edit={selectEdit as Shift} shiftTypes={_.orderBy(shiftType.items, (i) => i.id)} onComplete={handleShiftEditingComplete} />}
    </SimplePageWrapper>
}