import React from 'react';
import styles from './row-header.module.scss';
import {Box, Button} from '@mui/material';
import {IntlShape} from 'react-intl';
import {Lx} from './../../../../i18n/consts';
import ScheduleActionMenu from '../../action-menu';
import classNames from 'classnames';
import ProgressIndicator from '../../progress-indicator';
import TotalV2 from '../../total-v2';
import { TimeSheetItemView } from '../../time-shift';
import { TimeSheetItemStatus, TimeSheetItemType } from '../../../../features/schedule/types';
import TimeSheetGroupTool from '../../timesheet-group-tool';

interface Props {
    name: string,
    total: number,
    all: number
    approved: number,
    billable: number,
    approveAll: number;
    billAll: number;
    totalWorkHours: number;
    intl: IntlShape;
    onApproveAllClick: () => Promise<void>;
    onBillAllClick: () => Promise<void>;
    onDisapproveAllClick: () => Promise<void>;
    onCancelAllBillingClick: () => Promise<void>;
    onAddNewShiftClick: () => Promise<void>;
    hideButtons?: boolean;
}

const DataRowHeader: React.FC<Props> = (props) => {

    return(<Box className={styles.container}>
        <span className={styles.name}>{props.name}</span>
        <div className={styles.info}>
            <TotalV2 total={props.total} 
                allTotal={props.all} 
                approved={props.approved} 
                billed={props.billable} 
                approvable={props.approveAll} 
                billable={props.billAll} 
                totalWorkHours={props.totalWorkHours} />
            {
                props.hideButtons === undefined || !props.hideButtons && <>
                    <TimeSheetGroupTool intl={props.intl}
                        totalWorkhours={props.totalWorkHours}
                        approvable={props.approveAll}
                        billable={props.billAll}
                        approved={props.approved}
                        billed={props.billable}
                        onAddNewShiftClick={props.onAddNewShiftClick} 
                        onCancelBillClick={props.onCancelAllBillingClick} 
                        onBillClick={props.onBillAllClick} 
                        onDisaproveClick={props.onDisapproveAllClick} 
                        onApproveClick={props.onApproveAllClick} />
                </>
            }
        </div>
    </Box>)
}

export default DataRowHeader