import { REmployees } from '../roles/types';

export enum TimesheetViewType {
    DAY = 0,
    WEEK = 1,
    MONTH = 2
}

export enum TimeSheetGroupType {
    EMPLOYEEVIEW = 0,
    PROJECTVIEW = 1
}

export enum TimeSheetItemStatus {
    NORMAL = 0,
    APPROVED = 1,
    BILLABLE = 2
}

export enum TimeSheetItemType {
    SHIFT = 0,
    ABSENCE = 1
}

export type TimeSheetItem = {
    id: number;
    employeeName: string;
    date: string;
    targetName: string;
    targetId: number;
    note: string;
    internalNote: string;
    hours: number;
    status: TimeSheetItemStatus;
    timeshiftTypeId: number;
    timeshiftType: TimeSheetItemType;
}

export type Hours = {
    total: number;
    approved: number;
    billable: number;
    all: number;
}

export type DateRange = {
    from: moment.Moment;
    to: moment.Moment;
}

export type TimeSheetType = {
    id: number;
    timeSheetType: TimeSheetItemType;
}

export type ScheduleViewStatusFilter = {
    normal: boolean;
    approved: boolean;
    billable: boolean
}

export type TypeIdNameModel = {
    id: number;
    name: string;
    typeName: string;
}

export type TimeshiftUpdateState = {
    timeshiftId: number;
    previousStatus: TimeSheetItemStatus;
    newStatus: TimeSheetItemStatus;
}

export type PreEditState = {
    date?: string;
    shiftType?: number;
    project?: number;
    hours?: number;
    comment?: string;
}

export type CreateShift = {
    employees: Array<REmployees>;
    state?: PreEditState;
}

export type EditShift = PreEditState & {
    employee: REmployees;
}

export type ScheduleAutoCompleteItemValue = {
    id: number;
    name: string;
    group?: string;
    originalId: number | string;
}