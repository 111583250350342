import React, {useEffect, useState} from 'react';
import TimiDataGrid from '../../../../components/shared/grid';
import styles from './grid.module.scss';
import {
    GridApiRef,
    GridColumnProps,
    GridGroupByProps,
    GridIdNameModel,
    GridRowModel
} from '../../../../components/shared/grid/types';
import DataRowHeader from  '../../../../components/Schedule/data/row-header';
import {TimeSheetItemView} from '../../../../components/Schedule/time-shift';
import {TimeSheetItem, TimeSheetItemStatus, TimeshiftUpdateState, TimeSheetItemType} from '../../../../features/schedule/types';
import {Lx} from  '../../../../i18n/consts';
import {IntlShape} from 'react-intl';
import {TiminatorAbsenceTypes, TiminatorTypes} from './../../../../features/types/types';
import {TimeSheetGroupType} from './../../../../features/schedule/types';
import _ from 'lodash';
import {Employee} from './../../../../features/employees/types';
import {PIdNameModel} from './../../../../features/projects/types';

interface TimeSheetGridProps {
    data: TimeSheetItem[];
    calculateAllHours: (employees: number) => number;
    intl: IntlShape;
    shiftTypes: TiminatorTypes;
    absenceTypes: TiminatorAbsenceTypes;
    viewType: TimeSheetGroupType;
    projects: PIdNameModel[],
    forceSwitch: boolean;
    notifySwitch: (isAny: boolean) => void;
    onStateChange: (state: Array<TimeshiftUpdateState>) => Promise<void>;
    onTimeshiftUpdate: (timeshift: TimeSheetItem) => void;
    onAddNewShiftClick: (targetTimeshift: TimeSheetItem) => Promise<void>;
    onEditShiftClick: (timeshift: TimeSheetItem) => void;
    getShiftEmployee: (timeshift: TimeSheetItem) => Promise<Employee>;
}

const getGroupBy = (
    viewType: TimeSheetGroupType,
    calculateAllHours: (employees: number) => number,
    handleApproveAllClick: (timeshifts: Array<TimeSheetItem>) => Promise<void>,
    handleBillAllClick: (timeshifts: Array<TimeSheetItem>) => Promise<void>,
    handleDisapproveAllClick: (timeshift: Array<TimeSheetItem>) => Promise<void>,
    handleCancelAllBillingClick: (timeshift: Array<TimeSheetItem>) => Promise<void>,
    onAddNewShiftAddClick: (timeshift: TimeSheetItem) => Promise<void>
): GridGroupByProps => {
    const groupByField = viewType === TimeSheetGroupType.PROJECTVIEW ? (value: TimeSheetItem) => value.targetName : (value: TimeSheetItem) => value.employeeName
    const groupByKey = viewType === TimeSheetGroupType.PROJECTVIEW ? 'target' : 'employee'
    return {
        rowGroupingModel: viewType === TimeSheetGroupType.EMPLOYEEVIEW ? ['employeeName', 'date'] : ['targetName', 'employeeName'],
        groupByRenderer: (groupField, groupFieldValue, value, intl, level) => {
            switch (groupField) {
                case  'date':
                case 'targetName':
                case 'employeeName': {
                    const timeshifts = value as Array<TimeSheetItem>;
                    const totalView = shiftTotal(timeshifts);
                    const approveAll = getTotalCandidatesToApproveAll(timeshifts);
                    const billAll = getTotalCandidatesToBillApp(timeshifts);
                    let employeesCount = 1;
                    if (groupField === 'targetName') {
                        employeesCount = _.map(_.groupBy(timeshifts, (t) => t.employeeName), () => 1).reduce((i, a) => i + a, 0)
                    }
                    return (<DataRowHeader name={groupFieldValue}
                        total={totalView.total}
                        all={groupField === 'date' ? 0 : calculateAllHours(employeesCount)}
                        approved={totalView.approved}
                        billable={totalView.billable}
                        approveAll={approveAll}
                        billAll={billAll}
                        totalWorkHours={getTotalWorkHours(timeshifts)}
                        onApproveAllClick={async () => await handleApproveAllClick(timeshifts)}
                        onBillAllClick={async () => await handleBillAllClick(timeshifts)}
                        onDisapproveAllClick={async () => await handleDisapproveAllClick(timeshifts)}
                        onCancelAllBillingClick={async () => await handleCancelAllBillingClick(timeshifts)}
                        onAddNewShiftClick={async () => await onAddNewShiftAddClick({...timeshifts[0], date: ''})}
                        hideButtons={level !== undefined && level > 1}
                        intl={intl}/>)
                }

                default:
                    return (<></>)
            }
        }
    }
}

const getColumns = (
    viewType: TimeSheetGroupType,
    shiftTypes: TiminatorTypes,
    absenceTypes: TiminatorAbsenceTypes,
    projects: PIdNameModel[],
    onTimeshiftStateChange: (state: TimeshiftUpdateState) => void,
    onAddShiftClick: (timeshift: TimeSheetItem) => void,
    onEditShiftClick: (timeshift: TimeSheetItem) => void,
    getShiftEmployee: (timeshift: TimeSheetItem) => Promise<Employee>
): GridColumnProps[] => {
    return [
        {
            field: 'date', localizationKey: Lx.Schedule.TIMESHIFT_DATE, noHeader: true, type: 'date'
        },
        {
            field: 'target',
            localizationKey: Lx.Schedule.TIMESHIFT_TARGET,
            width: 160,
            editable: (row) => (row as TimeSheetItem).timeshiftType === TimeSheetItemType.SHIFT,
            type: 'singleSelect',
            selectableOptions: async (row): Promise<GridIdNameModel[]> => {
                const timeshift = row as TimeSheetItem;
                if (timeshift.timeshiftType !== TimeSheetItemType.SHIFT) return [];

                return _.chain((await getShiftEmployee(timeshift)).projects).orderBy((p) => p.id).map((x) => ({
                    id: x.id,
                    name: x.name
                })).value()
            },
            value: (row) => (row as TimeSheetItem).targetId,
            displayValue: (value, row, _intl) => {
                const timeshift = row as TimeSheetItem
                if (timeshift.timeshiftType === TimeSheetItemType.SHIFT) {
                    const id = value as number
                    return projects.find((x) => x.id === id)?.name ?? `Project [${id}]`
                }
                return timeshift.targetName;
            },
            visible: viewType === TimeSheetGroupType.EMPLOYEEVIEW
        },
        { field: 'employeeName', localizationKey: Lx.General.EMPLOYEE, width: 160, type: 'string', visible: viewType === TimeSheetGroupType.PROJECTVIEW },
        {
            field: 'type', localizationKey: Lx.Schedule.TIMESHIFT_TYPE, width: 160,
            value: (row) => ((row as TimeSheetItem).timeshiftTypeId),
            displayValue: (value, row, _intl) => {
                const timeshift = row as TimeSheetItem
                if (timeshift.timeshiftType === TimeSheetItemType.SHIFT) {
                    const id = value as number
                    return shiftTypes.items.find((x) => x.id === id)?.name ?? `ShiftType [${id}]`
                }
                return absenceTypes.items.find((x) => x.id === timeshift.timeshiftTypeId)?.name ?? 'AbsenceType'
            },
            editable: (row) => (row as TimeSheetItem).timeshiftType === TimeSheetItemType.SHIFT,
            type: 'singleSelect',
            selectableOptions: _.orderBy(shiftTypes.items.map((x) => ({ id: x.id, name: x.name })), (x) => x.id)
        },
        {field: 'note', localizationKey: Lx.Schedule.TIMESHIFT_NOTE, flex: true, muted: true, editable: true, type: 'text'},
        {field: 'internalNote', localizationKey: Lx.Schedule.TIMESHIFT_INTERNAL_NOTE, flex: true, muted: true, editable: true, type: 'text'},
        {
            field: 'hours', localizationKey: Lx.Schedule.TIMESHIFT_HOURS, width: 160, cellRender: (field, row, intl) => {
                const timeshift = row as TimeSheetItem;
                return (<div className={styles.timeShiftCell}>
                    <TimeSheetItemView hours={timeshift.hours} 
                        status={timeshift.status} 
                        intl={intl} note={timeshift.note} 
                        type={{ id: timeshift.timeshiftTypeId, timeSheetType: timeshift.timeshiftType }} 
                        size={'small'}
                        updateState={(status, previous) => 
                            onTimeshiftStateChange({ timeshiftId: timeshift.id, newStatus: status, previousStatus: previous })}
                        onAddNewShiftClick={() => onAddShiftClick(timeshift)}
                        onEditShiftClick={() => onEditShiftClick(timeshift)}/>
                </div>)
            }
        }
    ]
}

const shiftTotal = (viewItems: TimeSheetItem[]) => {
    return {
        total: viewItems.map((s) => s.hours).reduce((sum, a) => sum + a, 0),
        approved: viewItems.filter((s) => s.status == TimeSheetItemStatus.APPROVED || s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0),
        billable: viewItems.filter((s) => s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0)
    }
}

const getTotalCandidatesToApproveAll = (viewItems: TimeSheetItem[]): number => {
    return viewItems.filter((vi) => vi.timeshiftType === TimeSheetItemType.SHIFT && vi.status != TimeSheetItemStatus.APPROVED && vi.status != TimeSheetItemStatus.BILLABLE).map((vi) => vi.hours).reduce((sum, a) => sum + a, 0);
}

const getTotalCandidatesToBillApp = (viewItems: TimeSheetItem[]): number => {
    return viewItems.filter((vi) => vi.timeshiftType === TimeSheetItemType.SHIFT && vi.status != TimeSheetItemStatus.BILLABLE).map((vi) => vi.hours).reduce((sum, a) => sum + a, 0);
}

const getTotalWorkHours = (items: TimeSheetItem[]): number => {
    return items.filter((i) => i.timeshiftType === TimeSheetItemType.SHIFT).map((i) => i.hours).reduce((sum, a) => sum + a, 0);
}

const TimeSheetGrid: React.FC<TimeSheetGridProps> = (props) => {
    const { viewType, data, calculateAllHours, forceSwitch} = props;
    const [rows, setRows] = useState(data);
    const [apiRef, setApiRef] = useState<GridApiRef>({
        collapseDef: {
            forceSwitch,
            notifySwitch: (isAny: boolean) => props.notifySwitch(isAny)
        }
    })
    const handleApproveAllClick = async (timeshifts: Array<TimeSheetItem>) => {
        await props.onStateChange(timeshifts.filter((x) => x.timeshiftType === TimeSheetItemType.SHIFT && x.status === TimeSheetItemStatus.NORMAL).map((ts) => ({
            timeshiftId: ts.id,
            newStatus: TimeSheetItemStatus.APPROVED,
            previousStatus: ts.status
        })));
    }

    const handleBillAllClick = async (timeshifts: Array<TimeSheetItem>) => {
        await props.onStateChange(timeshifts.filter((x) => x.timeshiftType === TimeSheetItemType.SHIFT && x.status !== TimeSheetItemStatus.BILLABLE).map((ts) => ({
            timeshiftId: ts.id,
            newStatus: TimeSheetItemStatus.BILLABLE,
            previousStatus: ts.status
        })));
    }

    const handleDisapproveAllClick = async (timeshifts: Array<TimeSheetItem>) => {
        await props.onStateChange(timeshifts.filter((x) => x.timeshiftType === TimeSheetItemType.SHIFT && x.status !== TimeSheetItemStatus.NORMAL).map((ts) => ({
            timeshiftId: ts.id,
            newStatus: TimeSheetItemStatus.NORMAL,
            previousStatus: ts.status
        })))
    }

    const handleCancelAllBillingClick = async (timeshifts: Array<TimeSheetItem>) => {
        await props.onStateChange(timeshifts.filter((x) => x.timeshiftType === TimeSheetItemType.SHIFT
            && x.status === TimeSheetItemStatus.BILLABLE).map((ts) => ({
            timeshiftId: ts.id,
            newStatus: TimeSheetItemStatus.APPROVED,
            previousStatus: ts.status
        })))
    }

    const [columns, setColumns] = useState<Array<GridColumnProps>>(getColumns(
        props.viewType,
        props.shiftTypes,
        props.absenceTypes,
        props.projects,
        async (state) => await (props.onStateChange([state])),
        props.onAddNewShiftClick,
        props.onEditShiftClick,
        props.getShiftEmployee))
    const [groupBy, setGroupBy] = useState<GridGroupByProps>(getGroupBy(
        props.viewType,
        calculateAllHours,
        handleApproveAllClick,
        handleBillAllClick,
        handleDisapproveAllClick,
        handleCancelAllBillingClick,
        props.onAddNewShiftClick))

    useEffect(() => {
        setGroupBy(
            getGroupBy(
                viewType,
                calculateAllHours,
                handleApproveAllClick,
                handleBillAllClick,
                handleDisapproveAllClick,
                handleCancelAllBillingClick,
                props.onAddNewShiftClick))
        setColumns(
            getColumns(viewType,
                props.shiftTypes,
                props.absenceTypes,
                props.projects,
                async (state) => await (props.onStateChange([state])),
                props.onAddNewShiftClick,
                props.onEditShiftClick,
                props.getShiftEmployee))
    }, [viewType, data])

    useEffect(() => {
        setRows(data)
    }, [data]);

    useEffect(() => {
        setApiRef((prevState) => {
            return {
                collapseDef: {
                    ...prevState.collapseDef,
                    forceSwitch: forceSwitch
                }
            }
        })
    }, [forceSwitch])

    const handleRowEditEnd = (row: GridRowModel, state: GridRowModel) => {
        const rowTimeshift = row as TimeSheetItem;
        const changedData: TimeSheetItem[] = [];
        setRows(rows.map((d) => {
            const obj = { ...d };
            if (obj.id === rowTimeshift.id) {
                Object.keys(state).map((s) => {
                    switch (s) {
                        case 'type':
                            obj.timeshiftTypeId = state[s] as number;
                            break;
                        case 'note':
                            obj.note = state[s] as string;
                            break;
                        case 'internalNote':
                            obj.internalNote = state[s] as string;
                            break;
                        case 'target':
                            obj.targetId = state[s] as number;
                            break;
                    }
                })
                changedData.push(obj)
            }

            return obj
        }));
        props.onTimeshiftUpdate(changedData[0])
    };

    return (<TimiDataGrid hideHeader columns={columns} rows={rows} groupBy={groupBy} intl={props.intl} onRowEditEnd={handleRowEditEnd} editDataLoadingMode={'server'} apiRef={apiRef} />)
}

export default TimeSheetGrid;