import React from 'react';
import styles from './total.module.scss';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Lx} from '../../../i18n/consts';
import ProgressIndicator from '../progress-indicator';

interface Props {
    total: number;
    allTotal: number;
    approved: number;
    billed: number;
    approvable?: number;
    billable?: number;
    totalWorkHours?: number;
}

const TotalV2: React.FC<Props> = (props) => {
    const { total, allTotal, approved, billed, approvable, billable, totalWorkHours } = props;
    const calculatedApproved = totalWorkHours && (approved / totalWorkHours) * 100;
    const calculatedBilled = totalWorkHours && (billed / totalWorkHours) * 100;

    return (<div className={styles.total}>
        <div className={styles.totalPart}>
            <p><FormattedMessage id={Lx.Schedule.TOTAL_HOURS} />:
            </p>{allTotal < 1 ? total : <span><FormattedMessage id={Lx.General.ITEM_OF_ITEM_FORMAT} values={{f: Math.floor(total), s: Math.floor(allTotal)}}/></span>}
        </div>
        <div className={classNames({
            [styles.totalPart]: true,
            [styles.inactive]: approved < 1,
        })}>
            <p><FormattedMessage id={Lx.Schedule.APPROVED_HOURS} />:</p><span className={styles.totalApproved}>{Math.floor(approved)}</span>
            { <span className={classNames({
                [styles.indicator]: true,
                [styles.active]: approvable && approvable >= 0 && approved > 0
            })}>
                <ProgressIndicator type={'approve'} value={calculatedApproved ? calculatedApproved : 0}/>
            </span> }
        </div>
        <div className={classNames({
            [styles.totalPart]: true,
            [styles.inactive]: billed < 1,
        })}><p><FormattedMessage id={Lx.Schedule.BILLABLE_HOURS} />:</p><span className={styles.totalBillable}>{Math.floor(billed)}</span>
            { !!calculatedBilled && 
            <span className={classNames({
                [styles.indicator]: true,
                [styles.active]: billable && billable < 1 && billed > 0
            })}>
                <ProgressIndicator type={'billable'} value={calculatedBilled} />
            </span> }
        </div>
    </div>)
}

export default TotalV2;
