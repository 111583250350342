import {Shift} from '../../features/shifts/types';
import {CreateShift, TimeSheetItem, TimeSheetItemStatus, TimeshiftUpdateState} from '../../features/schedule/types';
import {TiminatorAbsenceType, TiminatorType} from '../../features/types/types';
import {Absence} from '../../features/absences/types';
import {Project} from '../../features/projects/types';

export const convertTimeshiftUpdateStateToShift = (updateState: TimeshiftUpdateState, shifts: Shift[]): Shift => {
    const shift = shifts.find((s) => s.id === updateState.timeshiftId);
    if (!shift) {
        return <Shift>{
            approved: false,
            billable: false,
            comment: '',
            note: '',
            date: '',
            employeeId: 0,
            enteredHoursString: '',
            hours: 0,
            id: 0,
            isOvertime: false,
            project: {},
            recurringPeriod: 0,
            remote: false,
            shiftType: {}
        }
    }

    return <Shift>{
        id: shift.id,
        employeeId: shift.employeeId,
        shiftType: shift.shiftType,
        hours: shift.hours,
        comment: shift.comment,
        note: shift.note,
        date: shift.date,
        enteredHoursString: shift.enteredHoursString,
        isOvertime: shift.isOvertime,
        project: shift.project,
        recurringPeriod: shift.recurringPeriod,
        remote: shift.remote,
        approved: updateState.newStatus === TimeSheetItemStatus.APPROVED || updateState.newStatus === TimeSheetItemStatus.BILLABLE,
        billable: updateState.newStatus === TimeSheetItemStatus.BILLABLE
    }
}

export const toShift = (timeshift: TimeSheetItem, shifts: Shift[], shiftTypes: TiminatorType[], project?: Project): Shift => {
    const shift = shifts.find((x) => x.id === timeshift.id)
    if (!shift) {
        return <Shift>{ }
    }

    const updated = { ...shift };

    updated.shiftType = shiftTypes.find((x) => x.id === timeshift.timeshiftTypeId) ?? shiftTypes[0];
    updated.hours = timeshift.hours;
    updated.comment = timeshift.note;
    updated.note = timeshift.internalNote;
    updated.enteredHoursString = `${timeshift.hours}`;
    updated.approved = timeshift.status === TimeSheetItemStatus.APPROVED || timeshift.status === TimeSheetItemStatus.BILLABLE;
    updated.billable = timeshift.status === TimeSheetItemStatus.BILLABLE;
    if (project){
        updated.project = {
            id: project.id,
            name: project.name,
            isActive: project.isActive,
            projectType: {
                id: project.projectType.id,
                name: project.projectType.name,
                default: project.projectType.isDefault,
                active: true
            }
        }
    }

    return updated
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const toAbsence = (timeshift: TimeSheetItem, absences: Absence[], absenceTypes: TiminatorAbsenceType[]): Absence => {
    const absence = absences.find((x) => x.id === timeshift.id)
    if (!absence) {
        return <Absence>{ }
    }
    const updated = {...absence};

    updated.hours = timeshift.hours;
    updated.enteredHoursString = `${timeshift.hours}`;
    updated.comment = timeshift.note;
    updated.note = timeshift.internalNote;

    return updated
}

export const isCreateShift = (obj: any): obj is CreateShift => {
    return obj != undefined && 'employees' in obj;
}

export const isEditShift = (obj: any): obj is Shift => {
    return obj != undefined && 'employeeId' in obj;
}
