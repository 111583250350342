import React from 'react';
import styles from './progress-indicator.module.scss'
import classNames from 'classnames';

interface Props {
    type: 'approve' | 'billable',
    value: number;
}

const ProgressIndicator: React.FC<Props> = (props) => {
    const { type, value } = props;
    return(<div className={classNames({
        [styles.progress]: true,
        [styles.approve]: type === 'approve',
        [styles.billable]: type === 'billable',
        [styles.active]: value > 0
    })}>
        <div className={classNames({
            [styles.soul]: true,
            [styles.full]: value === 100
        })} style={{ width: `${Math.round(value)}%` }} />
        <div className={classNames({
            [styles.mate]: true,
            [styles.full]: value === 100
        })}>
            <span />
        </div>
    </div>)
}

export default ProgressIndicator;