import {AutoCompleteItemValue} from './../../../../../components/shared/nextgen/selector/types';
import {ScheduleViewStatusFilter} from '../.././../../../features/schedule/types';
import styles from '../../schedule-header.module.scss';
import Selector from './../../../../../components/shared/nextgen/selector';
import {Lx} from './../../../../../i18n/consts';
import {SVGS} from './../../../../../assets/images';
import TypesSelector from '../types-selector';
import TimeSheetStatusSelector from './../../../timeshift-status-selector';
import React, {useState} from 'react';
import {TiminatorAbsenceType, TiminatorType} from './../../../../../features/types/types';
import {IntlShape} from 'react-intl';

interface FilterProps {
    items: AutoCompleteItemValue[]
    selected: AutoCompleteItemValue[],
    setSelected: (values: AutoCompleteItemValue[]) => void
}

interface StatusFilterProps {
    status: ScheduleViewStatusFilter,
    setFilter: (value: ScheduleViewStatusFilter) => void
}

interface ShiftTypeFilterProps extends Omit<FilterProps, 'items'>{
    items: TiminatorType[]
}

interface AbsenceTypeFilterProps extends Omit<FilterProps, 'items'>{
    items: TiminatorAbsenceType[]
}

interface Props {
    // eslint-disable-next-line react-redux/no-unused-prop-types
    search: {
        value: string,
        onChange: (value: string) => void
    };
    employees: FilterProps;
    projects: FilterProps;
    shiftTypes: ShiftTypeFilterProps;
    absenceTypes: AbsenceTypeFilterProps;
    statusFilter: StatusFilterProps;
    intl: IntlShape;
    isStatusFilterShow: boolean;
}
const Filter = (props: Props) => {
    const { intl } = props;
    
    return (<div className={styles.filter}>
        <div style={{display: 'flex', gap: '8px'}}>
            <input id={'search-bar-input'} className={styles.input} value={props.search.value} onChange={(event) => props.search.onChange(event.target.value)} placeholder={'Search'} size={15}/>
            <Selector id={'employeeFilter'}
                label={Lx.General.EMPLOYEES}
                items={props.employees.items}
                name={Lx.General.EMPLOYEES}
                svg={<SVGS.PersonIcon/>} selected={props.employees.selected}
                setSelectedValues={props.employees.setSelected} intl={intl}/>
            <Selector id={'projectsFilter'}
                label={Lx.General.PROJECTS}
                items={props.projects.items}
                name={Lx.General.PROJECTS}
                svg={<SVGS.ProjectsIcon/>}
                selected={props.projects.selected}
                setSelectedValues={props.projects.setSelected}
                intl={intl}/>
            <TypesSelector selectedShiftTypes={props.shiftTypes.selected}
                selectedAbsenceTypes={props.absenceTypes.selected}
                shiftTypes={props.shiftTypes.items}
                absenceTypes={props.absenceTypes.items}
                onShiftTypesSelected={props.shiftTypes.setSelected}
                onAbsenceTypesSelected={props.absenceTypes.setSelected}
                intl={intl}/>
            {props.isStatusFilterShow && <TimeSheetStatusSelector normal={props.statusFilter.status.normal}
                approved={props.statusFilter.status.approved}
                billable={props.statusFilter.status.billable}
                onNormalChanged={(active) => props.statusFilter.setFilter({
                    ...props.statusFilter.status,
                    normal: active
                })}
                onApprovedChanged={(active) => props.statusFilter.setFilter({
                    ...props.statusFilter.status,
                    approved: active
                })}
                onBillableChanged={(active) => props.statusFilter.setFilter({
                    ...props.statusFilter.status,
                    billable: active
                })}/>}
        </div>
    </div>)
};

export default Filter;