import React from 'react';
import {TimesheetViewType} from './../../../../../features/schedule/types';
import styles from './schedule-date-selector.module.scss';
import {IconButton} from '@mui/material';
import {SVGS} from './../../../../../assets/images';
import PopupMenuActions from './../../../../../components/shared/popup-menu';
import DateLabel from './../../../../../components/shared/nextgen/date-label';
import {PopupCalendar} from './../../../../../components/shared/calendar/index';
import {CalendarDate, Year} from './../../../../../components/shared/calendar/types';
import moment from 'moment/moment';

interface SelectorProps {
    selectedDates: Array<string>;
    // eslint-disable-next-line react-redux/no-unused-prop-types
    year: Year,
    // eslint-disable-next-line react-redux/no-unused-prop-types
    onRangeSelected: (range: string[]) => void;
}

const DaySelector = (props: SelectorProps) => {

    const handleRangeComplete = (dateRange: CalendarDate[]) => {
        props.onRangeSelected(dateRange.map((r) => `${r.year}/${r.month}/${r.day}`))
    }

    return(<div className={styles.selector}>
        <DateLabel value={props.selectedDates[0]} type={'day'} />
        {
            props.selectedDates[1] !== props.selectedDates[0] && <>
                <span className={styles.dash}>–</span>
                <DateLabel value={props.selectedDates[1]} type={'day'} />
            </>
        }
        <PopupCalendar year={props.year} view={'month'} range onRangeComplete={handleRangeComplete} selectedRange={props.selectedDates.map((x) => {
            const split = x.split('/').map((x) => parseInt(x));
            return { year: split[0], month: split[1], day: split[2] }
        })} />
    </div>)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const WeekSelector = (props: SelectorProps) => {
    return(<div className={styles.selector}>
        <DateLabel value={props.selectedDates[0]} type={'week'} />
        <PopupMenuActions actionIcon={<SVGS.CalendarIcon />} actions={[]} />
    </div>)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const MonthSelector = (props: SelectorProps) => {
    return(<div className={styles.selector}>
        <DateLabel value={props.selectedDates[0]} type={'month'} />
        <PopupMenuActions actionIcon={<SVGS.CalendarIcon />} actions={[]} />
    </div>)
}

interface Props {
    scheduleType: TimesheetViewType;
    selectedDates: Array<string>;
    onRangeSelected: (range: string[]) => void;
    year: Year
}

const ScheduleDateSelector: React.FC<Props> = (props) => {
    const { scheduleType, selectedDates, year, onRangeSelected } = props;

    const handleLeftButtonClick = () => { // move left selected date range
        const start = moment(selectedDates[0])
        const end = moment(selectedDates[1])
        const days = start.diff(end, 'days')
        if (days < 1) {
            const newDate = start.add(-1, 'days').format('yyyy/MM/DD');
            onRangeSelected([newDate, newDate])
        }
        const range = [start.clone().add(days, 'days').format('yyyy/MM/DD'), start.format('yyyy/MM/DD')];
        onRangeSelected(range)
    };

    const handleRightButtonClick = () => { // move right selected date range
        const start = moment(selectedDates[0])
        const end = moment(selectedDates[1])
        const days = end.diff(start, 'days')
        if (days < 1) {
            const newDate = end.add(1, 'days').format('yyyy/MM/DD');
            onRangeSelected([newDate, newDate])
            return
        }
        const range = [end.format('yyyy/MM/DD'), end.clone().add(days, 'days').format('yyyy/MM/DD')];
        onRangeSelected(range)
    };
    return(<div className={styles.container}>
        <IconButton className={styles.iconButton} onClick={() => handleLeftButtonClick()}><SVGS.LeftChevronIcon /></IconButton>
        {
            scheduleType == TimesheetViewType.DAY ? <DaySelector selectedDates={selectedDates} onRangeSelected={onRangeSelected} year={year} />
                : scheduleType == TimesheetViewType.WEEK ? <WeekSelector selectedDates={selectedDates} onRangeSelected={onRangeSelected} year={year} />
                    : <MonthSelector selectedDates={selectedDates} onRangeSelected={onRangeSelected} year={year} />
        }
        <IconButton className={styles.iconButton} onClick={() => handleRightButtonClick()}><SVGS.RightChevronIcon /></IconButton>
    </div>)
}

export default ScheduleDateSelector