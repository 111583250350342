import { Box, Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { TimeSheetItemStatus, TimeSheetItemType } from '../../../features/schedule/types';
import styles from './time-shift.module.scss'
import classNames from 'classnames';
import StateIcon from '../timesheet-state-icon';
import ToolActionMenu from './components/action-menu';
import { Lx } from '../../../i18n/consts';

interface Props {
    intl: IntlShape;
    approved: number;
    billed: number;
    approvable: number;
    billable: number;
    totalWorkhours: number;
    onAddNewShiftClick: () => Promise<void>;
    onCancelBillClick: () => Promise<void>;
    onBillClick: () => Promise<void>;
    onDisaproveClick: () => Promise<void>;
    onApproveClick: () => Promise<void>;
}

const ToolBox = ( props: { children: React.ReactNode } ) => {
    return(<Box className={styles.container}>
        {props.children}
    </Box>)
}

const getCurrentStateFromHours = (total: number, approved: number, billed: number): TimeSheetItemStatus => {
    if (total < 1) {
        return TimeSheetItemStatus.NORMAL;
    }
    if (billed == total){
        return TimeSheetItemStatus.BILLABLE;
    }

    if (approved == total) {
        return TimeSheetItemStatus.APPROVED;
    }

    return TimeSheetItemStatus.NORMAL;
}

const TimeSheetGroupTool: React.FC<Props> = (props) => {
    const { totalWorkhours, intl, approved, approvable, billed, billable } = props;
    const [state, setState] = useState<TimeSheetItemStatus>(getCurrentStateFromHours(totalWorkhours, approved, billed));
    const disabled = totalWorkhours < 1;
    const approveTooltipTitle = approvable > 0 ? intl.formatMessage({ id: Lx.Schedule.APPROVE_ALL_FORMAT }, { s: approvable }) : intl.formatMessage({ id: Lx.Schedule.DISAPPROVE_FORMAT }, { s: approved });
    const billTooltipTitle = billable > 0 ? intl.formatMessage({ id: Lx.Schedule.BILL_ALL_FORMAT}, { s: billable }) : intl.formatMessage({ id: Lx.Schedule.DISAPPROVE_FORMAT }, { s: billed });

    const handleApproveButtonClick = () => {
        if (state == TimeSheetItemStatus.BILLABLE || state == TimeSheetItemStatus.APPROVED) {
            Promise.resolve(props.onDisaproveClick());
            return;
        }

        Promise.resolve(props.onApproveClick());
    }

    const handleBillButtonClick = () => {
        if (state == TimeSheetItemStatus.BILLABLE) {
            Promise.resolve(props.onDisaproveClick());
            return;
        }

        Promise.resolve(props.onBillClick());
    }

    useEffect(() => {
        setState(getCurrentStateFromHours(totalWorkhours, approved, billed));
    }, [approved, billed])
    
    return(<ToolBox>
        <Box className={classNames({
            [styles.menu]: true,
        })}>
            <Tooltip title={approveTooltipTitle} disableInteractive={disabled} disableFocusListener={disabled} disableHoverListener={disabled}>
                <span>
                    <Button className={classNames({
                        [styles.state]: true,
                        [styles.approved]: state != TimeSheetItemStatus.NORMAL,
                    })} disabled={disabled} onClick={handleApproveButtonClick}>
                        <StateIcon status={state !== TimeSheetItemStatus.NORMAL ? TimeSheetItemStatus.APPROVED : TimeSheetItemStatus.NORMAL}
                            active={state !== TimeSheetItemStatus.NORMAL} />
                    </Button>
                </span>
            </Tooltip>
            {
                !disabled && <Tooltip title={billTooltipTitle}>
                    <span>
                        <Button className={classNames({
                            [styles.state]: true,
                            [styles.billable]: state == TimeSheetItemStatus.BILLABLE
                        })} onClick={handleBillButtonClick} >
                            <StateIcon status={TimeSheetItemStatus.BILLABLE} active={state === TimeSheetItemStatus.BILLABLE} />
                        </Button>
                    </span>
                </Tooltip>
            }
            <ToolActionMenu intl={intl}
                status={state}
                approvedHours={approved}
                approvableHours={approvable}
                billedHours={billed}
                billableHours={billable}
                isPossibleToUpdate={!disabled}
                onAddNewShiftClick={props.onAddNewShiftClick} 
                onApproveClick={props.onApproveClick} 
                onDisaproveClick={props.onDisaproveClick} 
                onBillClick={props.onBillClick} 
                onCancelBillClick={props.onCancelBillClick} />
        </Box>
    </ToolBox>)
}

export default TimeSheetGroupTool;