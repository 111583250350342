import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import EditModal from '../../../../components/shared/modal/edit-modal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import {PIdNameModel, Project} from '../../../../features/projects/types';
import {getProjects, updateProject} from '../../../../features/projects';
import {FormDropdown} from '../../../../components/shared/form-controls/form-dropdown';
import FormAutocomplete from '../../../../components/shared/form-controls/form-autocomplete-tag';
import {REmployees} from '../../../../features/roles/types';
import {filterProjects} from '../../../../features/projects/filter';

interface Props {
    project: Project;
    employees: REmployees[],
    types: PIdNameModel[],
    clients: PIdNameModel[],
    roles: PIdNameModel[]
}

interface EditForm {
    name: string;
    isActive: boolean,
    client?: PIdNameModel,
    projectType: PIdNameModel,
    projectManager?: PIdNameModel,
    roleForNotification?: PIdNameModel,
    employees?: REmployees[],
    note?: string,
}

export default function EditProjectModal(props: Props) {
    const {project} = props;
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const id = project.id;

    const initialValues: EditForm = {
        name: project.name,
        isActive: project.isActive,
        employees: project.employees,
        client: project.client,
        note: project.note,
        projectManager: project.projectManager,
        projectType: project.projectType,
        roleForNotification: project.roleForNotification
    };

    useEffect(() => {
        openModal();
    }, [])

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }

        return await dispatch(filterProjects(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name) || name === initialValues.name.toLowerCase();
        });
    }

    const save = async (values: EditForm) => {
        const updated: Project = {
            id,
            canEditEmployees: false,
            name: values.name,
            note: values.note ?? '',
            isActive: values.isActive,
            client: values.client,
            projectType: values.projectType,
            projectManager: values.projectManager,
            roleForNotification: values.roleForNotification,
            employees: values.employees ?? [],
            canEditClient: false,
            canEditProjectManager: false,
            canEditRoleForNotification: false
        }

        return dispatch(updateProject(updated)).unwrap().then(() => {
            onToggle();
            dispatch(getProjects({projectTypeId: project.projectType.id, page: 0}));
        })
    }

    const validationScheme: Yup.Schema<EditForm> = Yup.object().shape({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required('Required')
            .test(
                'project-name-backend-validation',
                'Project name is taken',
                async (name: string) => { return await validateName(name); }
            ),
        isActive: Yup.boolean().required(),
        projectType: Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            isDefault: Yup.boolean().required(),
        }).required()
    })

    return <span>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={save}>
            <Form>
                <EditModal open={open} title='project' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Name'/>
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                    {project.canEditClient && <FormDropdown
                        label='Client'
                        name='client'
                        required={false}
                        items={props.clients}/>}
                    <FormDropdown
                        label='Type'
                        name='projectType'
                        required={true}
                        items={props.types}/>
                    {project.canEditProjectManager && <FormDropdown
                        label='Project manager'
                        name='projectManager'
                        required={false}
                        items={props.employees}/>}
                    {project.canEditEmployees && <FormAutocomplete
                        placeHolder='Start typing to add'
                        label='Employee'
                        name='employees'
                        items={props.employees}
                        selectedItems={project.employees}/>}
                    {project.canEditRoleForNotification && <FormDropdown
                        label='Role for notification'
                        name='roleForNotification'
                        required={false}
                        items={props.roles}/>}
                    <FormInput name='note' label='Note' placeholder='Write something' required={false}/>
                </EditModal>
            </Form>
        </Formik>}
    </span>
}
