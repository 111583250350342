import React from 'react';
import styles from './timeshift-status-selector.module.scss';
import classNames from 'classnames';
import {SVGS} from './../../../assets/images';
import Button from './selector-theme'
import Tooltip from '@mui/material/Tooltip';
import {useIntl} from 'react-intl';
import {Lx} from '../../../i18n/consts';

export enum TimeshiftStatus {
    NORMAL = 0,
    APPROVED = 1,
    BILLABLE = 2
}

interface Props {
    normal: boolean;
    approved: boolean;
    billable: boolean;
    onNormalChanged: (active: boolean) => void;
    onApprovedChanged: (active: boolean) => void;
    onBillableChanged: (active: boolean) => void
}

const StatusTypeIcon = (props: { status: TimeshiftStatus }) => {
    const { status } = props;
    return (status === TimeshiftStatus.NORMAL ? <SVGS.CheckIcon className={classNames({
        [styles.icon]: true,
    })}/> : status === TimeshiftStatus.APPROVED ? <SVGS.ApproveIcon className={styles.icon}/> :
        <SVGS.MoneyIcon className={styles.icon}/>)
}

const TimeSheetItemTypeSelector = (props: { status: TimeshiftStatus, active: boolean, onChange: (active: boolean) => void }) => {
    const { status, active } = props;
    const icon = <StatusTypeIcon status={status} />;
    return (<Button
        color={'primary'}
        onClick={() => props.onChange(!active)}
        className={active ? 'active' : ''} >{icon}</Button>)
}

const TimeSheetStatusSelector: React.FC<Props> = (props) => {
    const { normal, approved, billable, onNormalChanged, onApprovedChanged, onBillableChanged } = props;
    const intl = useIntl();

    return(<div className={styles.container}>
        <Tooltip
            title={normal ?
                intl.formatMessage({id: Lx.General.HIDE_SMTH}, {s: intl.formatMessage({id: Lx.Schedule.REGULAR_HOURS})})
                : intl.formatMessage({id: Lx.General.SHOW_SMTH}, {s: intl.formatMessage({id: Lx.Schedule.REGULAR_HOURS})})}>
            <div className={styles.wrapper}>
                <TimeSheetItemTypeSelector status={TimeshiftStatus.NORMAL} active={normal} onChange={onNormalChanged} />
            </div>
        </Tooltip>
        <Tooltip title={approved ?
            intl.formatMessage({id: Lx.General.HIDE_SMTH}, {s: intl.formatMessage({id: Lx.Schedule.APPROVED_HOURS})})
            : intl.formatMessage({id: Lx.General.SHOW_SMTH}, {s: intl.formatMessage({id: Lx.Schedule.APPROVED_HOURS})})}>
            <div className={styles.wrapper}>
                <TimeSheetItemTypeSelector status={TimeshiftStatus.APPROVED} active={approved} onChange={onApprovedChanged} />
            </div>
        </Tooltip>
        <Tooltip title={billable ?
            intl.formatMessage({id: Lx.General.HIDE_SMTH}, {s: intl.formatMessage({id: Lx.Schedule.BILLABLE_HOURS})})
            : intl.formatMessage({id: Lx.General.SHOW_SMTH}, {s: intl.formatMessage({id: Lx.Schedule.BILLABLE_HOURS})})}>
            <div className={styles.wrapper}>
                <TimeSheetItemTypeSelector status={TimeshiftStatus.BILLABLE} active={billable} onChange={onBillableChanged} />
            </div>
        </Tooltip>
    </div>)
}

export default TimeSheetStatusSelector;