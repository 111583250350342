import React from 'react';
import styles from './time-shift.module.scss';
import classNames from 'classnames';
import {TimeSheetItemStatus, TimeSheetType, TimeSheetItemType} from '../../../features/schedule/types';
import {IntlShape} from 'react-intl';
import TimeSheetActionMenu from './components/action-menu';
import {Button} from '@mui/material';
import StateIcon from '../timesheet-state-icon';


interface Props {
    intl: IntlShape;
    hours: number;
    status: TimeSheetItemStatus;
    note: string;
    type: TimeSheetType;
    size?: 'small' | 'default';
    updateState: (status: TimeSheetItemStatus, previous: TimeSheetItemStatus) => void;
    onAddNewShiftClick: () => void;
    onEditShiftClick: () => void;
}

export const TimeSheetItemView: React.FC<Props> = (props) => {
    const { intl, hours, status, type, note, size } = props;

    const handleApproveClick = () => {
        props.updateState(status === TimeSheetItemStatus.NORMAL ? TimeSheetItemStatus.APPROVED : TimeSheetItemStatus.NORMAL, status);
    }

    const handleBillClick = () => {
        props.updateState(status !== TimeSheetItemStatus.BILLABLE ? TimeSheetItemStatus.BILLABLE : TimeSheetItemStatus.APPROVED, status);
    }

    return(<div className={classNames({
        [styles.container]: true,
        [styles.wrap]: !size || size == 'small'
    })}>
        <div className={classNames({
            [styles.col]: true,
            [styles.wrap]: !size || size == 'small'
        })}>
            <div className={styles.hours}>{hours} h</div>
            {
                size && size == 'default' ? <>
                    <div className={styles.content}>{type.timeSheetType}</div>
                    <div className={styles.content}>{note}</div>
                </> : <></>
            }
        </div>
        <div className={classNames({
            [styles.menu]: true,
            [styles.wrap]: !size || size == 'small'
        })}>
            <Button className={classNames({
                [styles.state]: true,
                [styles.approved]: status != TimeSheetItemStatus.NORMAL,
            })} disabled={type.timeSheetType !== TimeSheetItemType.SHIFT} onClick={() => handleApproveClick()}>
                <StateIcon status={status !== TimeSheetItemStatus.NORMAL ? TimeSheetItemStatus.APPROVED : TimeSheetItemStatus.NORMAL} active={status !== TimeSheetItemStatus.NORMAL}/>
            </Button>
            {
                type.timeSheetType === TimeSheetItemType.SHIFT && <Button className={classNames({
                    [styles.state]: true,
                    [styles.billable]: status == TimeSheetItemStatus.BILLABLE
                })} onClick={() => handleBillClick()} >
                    <StateIcon status={TimeSheetItemStatus.BILLABLE} active={status === TimeSheetItemStatus.BILLABLE}/>
                </Button>
            }
            <TimeSheetActionMenu intl={intl}
                status={status}
                hours={hours}
                timeshiftType={type.timeSheetType}
                onStatusChangeClick={props.updateState}
                onAddNewShiftClick={props.onAddNewShiftClick}
                onEditShiftClick={props.onEditShiftClick}/>
        </div>
    </div>)
}